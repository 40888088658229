function navigationOverlayToggle() {
  const menuButton = document.querySelector("#navigation__menu-button");
  const navigationWrapper = document.querySelector("#navigation");

  if (!menuButton && !navigationWrapper) {
    return;
  }

  menuButton.addEventListener("click", () => {
    navigationWrapper.classList.toggle("navigation--open");

    menuButton.innerHTML =
      menuButton.innerHTML === "Schließen" ? "Menu" : "Schließen";
  });
}

export default navigationOverlayToggle;
